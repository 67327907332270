import { useState, useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import './PaymentSuccess.css';
import docuParseLogo from '../images/docuparse-logo.png';
import scionLogo from '../images/scion-logo.png';
import ReactLoading from 'react-loading';


export default function PaymentSuccess(props) {
    const [params, setParams] = useSearchParams();
    const [config, setConfig] = useState({});
    const [checkoutSessionId, setCheckoutSessionId] = useState(null);
    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadFileInfo, setDownloadFileInfo] = useState({});
    const [downloadSuccess, setDownloadSuccess] = useState(null);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [title, setTitle] = useState(null);
    const [subtitle, setSubtitle] = useState("Your download will begin shortly.")
    const navigate = useNavigate();

    const downloadDocument = async () => {
        const payload = {
            'documentId': downloadFileInfo.documentId,
            'fileName': downloadFileInfo.parsedFileName
        };

        const resp = await fetch('/documents/download', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });

        if (resp.ok){
            const blob = await resp.blob();
            const file = window.URL.createObjectURL(blob);
            window.location.assign(file);
            setDownloadSuccess(true);
        } else {
            setDownloadSuccess(false);
        }
        
        setIsDownloading(false);
    }

    const getCheckoutSessionInfo = async () => {
        setIsDownloading(true);
        const resp = await fetch(`/payments/checkout-success?session_id=${checkoutSessionId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (resp.ok){
            const respJson = await resp.json();
            if (respJson && respJson.parsedFileName) {
                setDownloadFileInfo(respJson);
            }
        } else {
            setDownloadSuccess(false);
            setIsDownloading(false);
        }
    }

    const getConfig = async () => {
        let resp = await fetch("/config", {
            method: 'GET',
            //mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            }
        });

        const respJson = await resp.json();

        if (respJson.success) {
            setConfig(respJson);
        } else {
            // show error modal
        }
    }

    useEffect(() => {
        if (downloadSuccess !== null){
            if (downloadSuccess && downloadFileInfo && downloadFileInfo.parsedFileName){
                setSubtitle(config.stripePaymentSuccessSubtitle)
            } else {
                setSubtitle(config.stripePaymentFailSubtitle);
            }
        }
    }, [downloadSuccess]);

    useEffect(() => {
        if (downloadFileInfo && downloadFileInfo.documentId && downloadFileInfo.parsedFileName) {
            downloadDocument();
        }
    }, [downloadFileInfo]);

    useEffect(() => {
        if (config.success){
            if (paymentSuccess && checkoutSessionId){
                setTitle(config.stripePaymentSuccessTitle);
                getCheckoutSessionInfo();
            } else {
                setTitle(config.stripePaymentFailTitle);
                setSubtitle(config.stripePaymentFailSubtitle);
            }
        }
        
    }, [checkoutSessionId, paymentSuccess, config]);

    useEffect(() => {
        
        getConfig();
        
        const csiParam = params.get("session_id");
        const successParam = params.get("success");

        if (csiParam) {
            setCheckoutSessionId(csiParam);
        } else {
            // redirect back to home
            navigate("/");
        }

        if (successParam) {
            const success = (successParam === 'true');
            setPaymentSuccess(success);
        }
    }, []);

    return (
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <div>
                <span className="title">{title}</span>
            </div>
            
            <div className='my-3'>{subtitle}</div>
            
            <div className='is-downloading-wrapper'>
                {isDownloading && 
                    <ReactLoading type="cylon" width={72} color={'#00b7c4'}/>
                }
                
                {downloadSuccess !== null && downloadSuccess && 
                    <i class="bi bi-check-circle fs-1 text-success"></i>
                }
            </div>
            <div className='mb-4'>
                <a href="/" rel="noreferrer">{config.stripePaymentSuccessLinkText}</a>
            </div>
            <div className="payment-success-logo">
                <div>
                <a href="/" rel="noreferrer"><img src={docuParseLogo} className="payment-success-logo__img" alt="logo" /></a>
                </div>
                <div>
                    <a href="https://www.scionanalytics.com" target="_blank" rel="noreferrer"><img src={scionLogo} className="payment-success-logo__img" alt="logo" /></a>
                </div>
            </div>
        </div>
    )
}