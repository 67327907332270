import { useState, useEffect, useRef } from 'react';
import './App.css';
import PaymentSuccess from './components/PaymentSuccess';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import HomePage from './components/HomePage';
import RootLayout from './components/RootLayout';


function App() {
    const handleAppDragOver = (evt) => {
        evt.preventDefault();
        if (evt.target.id == "dropzone" || evt.target.closest("#dropzone")) {
            document.querySelector("#dropzone").classList.add("drag-hover");
        } else {
            document.querySelector("#dropzone").classList.remove("drag-hover");
        }
    }

    const handleAppDrop = (evt) => {
        evt.preventDefault();
    }

    const router = createBrowserRouter([
        {
            path: '/',
            element: <RootLayout />,
            children: [
                { path: '/', element: <HomePage/> },
                { path: '/complete', element: <PaymentSuccess/> }
            ]
        }
    ]);

    return (
        <div className="App" onDragOver={handleAppDragOver} onDrop={handleAppDrop}>
            <RouterProvider router={router}/>
        </div>
    );
}

export default App;