import { Outlet } from 'react-router-dom';
import './RootLayout.css';
import logo from '../images/blue_horizontal_logo_white_logo_logo_v2.svg'

export default function RootLayout(props) {
    return (
        <div id='root-container' className="container-fluid h-100">
            <div id='root-header' className='row'>
                <div className='col-lg-12'>
                    <img src={logo}/>
                </div>
                
            </div>
            <div id='root-body' className='row'>
                <Outlet/>
            </div>
            
            <div id='root-container-backdrop'></div>
        </div>
    )
}