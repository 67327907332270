import { useState, useEffect, useRef } from 'react';
import './HomePage.css';
import wordIcon from '../images/Word_72x72.png';
import pdfIcon from '../images/PDF_72x72.png';
import ReactLoading from 'react-loading';
import { loadStripe } from "@stripe/stripe-js";
import { error } from 'jquery';
import Lottie from 'react-lottie-player';
import lottieJson from '../images/animation_lknty9c1.json';
import { useSearchParams } from 'react-router-dom';
import './DynamicTextArea.css';
let stripePromise = null;

// get Stripe public key outside App component
fetch("/config", {
    method: 'GET',
    //mode: 'cors',
    headers: {
        "Content-Type": "application/json"
    }
})
    .then((resp) => resp.json())
    .then((data) => {
        stripePromise = loadStripe(data.stripeApiPublicKey);
    });

function HomePage() {
    const allDictionariesRef = useRef();
    const textAreaRef = useRef();
    const parseTypeRef = useRef();
    const dropzoneFileInputRef = useRef();
    const resultsPreviewRef = useRef();

    const [previewHtml, setPreviewHtml] = useState(null);
    const [config, setConfig] = useState({});
    const [activeStep, setActiveStep] = useState(1);
    const [params, setParams] = useSearchParams();
    const [isUploading, setIsUploading] = useState(false);
    const [downloadFileInfo, setDownloadFileInfo] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const [parseResult, setParseResult] = useState(null);
    const [stagedFile, setStagedFile] = useState(null);
    const [keywords, setKeywords] = useState([]);
    const [keywordLines, setKeywordLines] = useState();
    const [savedKeywordList, setSavedKeywordList] = useState();
    const [govconIsChecked, setGovconIsChecked] = useState(false);
    const [legalIsChecked, setLegalIsChecked] = useState(false);
    const [financialIsChecked, setFinancialIsChecked] = useState(false);

    const [modalOptions, setModalOptions] = useState({
        visible: false,
        loading: false,
        title: "",
        body: ""
    });

    const [paymentModalOptions, setPaymentModalOptions] = useState({
        visible: false,
        loading: false,
        title: "",
        body: ""
    });

    const [tutorialModalOptions, setTutorialModalOptions] = useState({
        visible: false
    });

    

    const processFile = (file) => {
        const fileExt = file.name.split(".").pop();

        // confirm file type is allowed
        const allowedFileExtensions = config.SupportedFileTypes.map(x => x.fileExtension);

        if (!allowedFileExtensions.includes(fileExt)) {
            setModalOptions({ ...modalOptions, visible: true, userConfirm: false, title: "Error", body: "This file type not supported.  Supported file types include .pdf, .doc and .docx" });
        } else if (file.size === 0) {
            setModalOptions({ ...modalOptions, visible: true, userConfirm: false, title: "Error", body: "This file is empty (0) bytes) and cannot be uploaded." });
        } else {
            const maxBytes = config.SupportedFileTypes.filter(x => x.fileExtension === fileExt)[0].maxBytes;

            if (file.size > maxBytes) {
                setModalOptions({ ...modalOptions, visible: true, userConfirm: false, title: "Error", body: "This file is too large to be uploaded." });
            } else {
                file.isSelected = false;
                setStagedFile(file);
            }
        }
    }

    const handleDropzoneDrop = (evt) => {
        evt.preventDefault();
        if (evt.dataTransfer.items && evt.dataTransfer.items.length) {
            if (evt.dataTransfer.items.length === 1) {
                const file = evt.dataTransfer.items[0].getAsFile();

                processFile(file);
            } else {
                setModalOptions({ ...modalOptions, visible: true, userConfirm: false, body: "Only one file may be uploaded at a time." });;
            }
        }

        document.querySelector("#dropzone").classList.remove("drag-hover");
    }

    const handleUploadButtonClick = (evt) => {
        dropzoneFileInputRef.current.click();
    }

    const handleFileInputChange = (evt) => {
        const file = evt.target.files[0];
        processFile(file);
        evt.target.value = null;
    }

    const checkIllegalCharacters = (keywords) => {
        if (keywords) {
            const str = keywords.join("");
            const pattern = /^[-' a-zA-Z0-9]+$/;
            return pattern.test(str);
        }

        return true;
    }

    const handleParseDocumentClick = async () => {
        // get edited keywords
        const text = textAreaRef.current.value;
        const keywordList = text ? text.replace(/(\r\n|\r|\n)/g, "|").split("|") : null;
        setSavedKeywordList(keywordList);

        // check for unsupported chars in dictionary
        const dictionaryIsSupported = checkIllegalCharacters(keywordList);
        if (dictionaryIsSupported) {
            const formData = new FormData();
            formData.append("File", stagedFile);

            const documentParse = {
                "keywords": keywordList,
                "parseType": parseTypeRef.current.value
            }

            formData.append("documentParseInfo", JSON.stringify(documentParse));

            try {
                // show loading animation
                setActiveStep(2.5);

                // same origin API call to /documents endpoint
                const resp = await fetch("/documents", {
                    method: "POST",
                    body: formData
                });

                if (resp.ok) {
                    let respJson = await resp.json();
                    if (respJson.success) {
                        setParseResult(respJson);
                    } else {
                        throw new error;
                    }
                } else {
                    throw new error;
                }
            } catch (error) {
                // hide loading animation
                setActiveStep(2);

                // show error message
                setModalOptions({ ...modalOptions, visible: true, userConfirm: false, title: "Error", body: "There was a problem parsing your document." });
            }

        } else {
            // modal warning
            setModalOptions({ ...modalOptions, visible: true, userConfirm: false, title: "Error", body: "The dictionary you have tried to run has unsupported characters. Supported characters are English alpha-numeric characters and the following special characters - '" });;
        }
    }

    const handleModalClose = (evt) => {
        evt.preventDefault();
        let targetModal = evt.target.getAttribute('data-dismiss');
        if (targetModal === "modal") {
            setModalOptions({ ...modalOptions, visible: false, title: "", body: "" });
        }

        if (targetModal === "tutorial-modal") {
            setTutorialModalOptions({ ...tutorialModalOptions, visible: false });
        }
    }

    const restoreKeywords = async () => {
        textAreaRef.current.value = savedKeywordList.join("\r\n");
    }

    const handleTextAreaChange = (evt) => {
        setKeywordLines(evt.target.value);
    }

    const handleToggleGovcon = (evt) => {
        setGovconIsChecked(!govconIsChecked);
        handleToggleKeywords();
    }

    const handleToggleLegal = (evt) => {
        setLegalIsChecked(!legalIsChecked);
        handleToggleKeywords();
    }

    const handleToggleFinancial = (evt) => {
        setFinancialIsChecked(!financialIsChecked);
        handleToggleKeywords();
    }

    const handleToggleKeywords = () => {
        const checkedDicts = Array.from(document.querySelectorAll('input.dictionary-option:checked')).map(x => x.dataset.dictName);

        if (allDictionariesRef && allDictionariesRef.current.length) {
            let userWords = [];
            const allDictKeywordsOnly = allDictionariesRef.current.map(x => x.keyword);
            const currentTextAreaKeywords = textAreaRef.current.value ? textAreaRef.current.value.replace(/(\r\n|\r|\n)/g, "|").split("|") : null;

            if (currentTextAreaKeywords && currentTextAreaKeywords.length) {
                userWords = currentTextAreaKeywords.filter(x => !allDictKeywordsOnly.includes(x)).map(x => {
                    return {
                        dict: 'user',
                        keyword: x
                    }
                });
            }

            let updatedKeywords = [];
            checkedDicts.forEach(d => {
                const keywords = allDictionariesRef.current.filter(x => x.dict === d);
                updatedKeywords = [...updatedKeywords, ...keywords];
            });

            updatedKeywords = [...userWords, ...updatedKeywords];

            // textAreaRef.current.value = updatedKeywords.map(k => k.keyword).join("\r\n");
            setKeywordLines(updatedKeywords.map(k => k.keyword).join("\r\n"));
        }
    }

    const handleDictionaryChange = () => {
        const checkedDicts = Array.from(document.querySelectorAll('input.dictionary-option:checked')).map(x => x.dataset.dictName);

        if (allDictionariesRef && allDictionariesRef.current.length) {
            let userWords = [];
            const allDictKeywordsOnly = allDictionariesRef.current.map(x => x.keyword);
            const currentKeywords = textAreaRef.current.getText() ? textAreaRef.current.getText().replace(/(\r\n|\r|\n)/g, "|").split("|") : null;

            if (currentKeywords && currentKeywords.length) {
                userWords = currentKeywords.filter(x => !allDictKeywordsOnly.includes(x)).map(x => {
                    return {
                        dict: 'user',
                        keyword: x
                    }
                });
            }

            let updatedKeywords = [];
            checkedDicts.forEach(d => {
                const keywords = allDictionariesRef.current.filter(x => x.dict === d);
                updatedKeywords = [...updatedKeywords, ...keywords];
            });

            if (userWords && userWords.length) {
                updatedKeywords = [...userWords, ...updatedKeywords];
            }

            setKeywords(updatedKeywords);
        } else {
            // keep only the user-defined keywords
            setKeywords([]);
        }
    }

    const handleDownloadClick = async () => {
        await downloadDocument();
    }

    const handlePurchaseClick = async () => {
        await createCheckoutSession();
    }

    const handlePaymentSucceeded = async () => {
        setPaymentModalOptions({ ...paymentModalOptions, visible: false });

        // download full parse result file
        const payload = {
            'documentId': parseResult.documentId,
            'fileName': parseResult.parsedFileName
        };

        const resp = await fetch('/documents/download', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });

        const blob = await resp.blob();
        const file = window.URL.createObjectURL(blob);
        window.location.assign(file);
    }

    const handlePaymentFailed = (data) => {
        setPaymentModalOptions({ ...paymentModalOptions, visible: false });
    }

    const handlePaymentCancelled = (evt) => {
        evt.preventDefault();
        setPaymentModalOptions({ ...paymentModalOptions, visible: false });
    }

    const handleStartOver = () => {
        setStagedFile(null);
        setActiveStep(1);

        try{
            params.delete("session_id");
            params.delete("success");
            setParams(params);
        } catch {
            // no params to delete
        }
    }

    const createCheckoutSession = () => {
        const payload = {
            'documentId': parseResult.documentId,
            'fileName': parseResult.parsedFileName
        };
        fetch("/payments/create-checkout-session", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload)
        })
            .then((res) => res.json())
            .then((data) => {
                window.location.href = data.redirectUrl;
            });
    }

    const processParseResult = async () => {
        if (parseResult.error) {
            // show error message
            setModalOptions({ ...modalOptions, visible: true, userConfirm: false, title: "Error", body: "There was a problem parsing your document.  This could be due to a corrupted file, encryption, or password protection." });
        } else {
            if (parseResult.documentId != null && parseResult.previewHtml != null) {
                // setPreviewSrc(`data:image/png;base64, ${parseResult.imageBase64}`);
                setPreviewHtml(parseResult.previewHtml);

                // hide modal
                setModalOptions({ ...modalOptions, visible: false, loading: false });
            }
        }
    };

    const getConfig = async () => {
        let resp = await fetch("/config", {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        });

        const respJson = await resp.json();

        if (respJson.success) {
            const configSettings = {
                ...respJson,
                maxWordMb: Math.round((respJson.SupportedFileTypes.find(x => x.fileExtension === 'docx').maxBytes / 1048576) * 10) / 10,
                maxPdfMb: Math.round((respJson.SupportedFileTypes.find(x => x.fileExtension === 'pdf').maxBytes / 1048576) * 10) / 10
            };
            setConfig(configSettings);
        } else {
            // show error modal
        }
    }

    const getCheckoutSessionInfo = async (sessionId) => {
        const resp = await fetch(`/payments/checkout-success?session_id=${sessionId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (resp.ok) {
            const respJson = await resp.json();
            if (respJson && respJson.parsedFileName) {
                setDownloadFileInfo(respJson);
            }
        } else {
            // problem with checkout
        }
    }

    const downloadDocument = async () => {
        const payload = {
            'documentId': downloadFileInfo.documentId,
            'fileName': downloadFileInfo.parsedFileName
        };

        const resp = await fetch('/documents/download', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });

        if (resp.ok) {
            const blob = await resp.blob();
            const file = window.URL.createObjectURL(blob);
            window.location.assign(file);
        } else {
            // problem downloading result
        }
    }

    useEffect(() => {
        if (downloadFileInfo && downloadFileInfo.documentId && downloadFileInfo.parsedFileName) {
            downloadDocument();
        }
    }, [downloadFileInfo]);

    useEffect(() => {
        if (parseResult) {
            setActiveStep(3);
            processParseResult();
        }
    }, [parseResult]);

    const handleFileStagingSuccess = () => {
        setActiveStep(2);
        setIsUploading(false);
    }

    useEffect(() => {
        if (stagedFile) {
            setIsUploading(true);
            const r = setTimeout(handleFileStagingSuccess, 1000);
        }
    }, [stagedFile]);

    useEffect(() => {
        if (previewHtml) {
            resultsPreviewRef.current.innerHTML = previewHtml;
        }
    }, [previewHtml]);

    useEffect(() => {
        if (activeStep === 2) {
            // check boxes based on previous keywords
            if (keywordLines) {
                const list = keywordLines.replace(/(\r\n|\r|\n)/g, "|").split("|");

                ['GovCon', 'Legal', 'Financial'].forEach((item) => {
                    const dictKeywords = allDictionariesRef.current.filter(x => x.dict === item).map(x => x.keyword);
                    const matches = list.filter(x => dictKeywords.includes(x));
                    console.log(`found ${matches.length} matches from ${item} dictionary`);
                    if (matches.length >= 3){
                        // check the box
                        if (item === 'GovCon') {
                            setGovconIsChecked(true);
                        }

                        if (item === 'Legal') {
                            setLegalIsChecked(true);
                        }

                        if (item === 'Financial') {
                            setFinancialIsChecked(true);
                        }
                    }
                });
            }
        }
    }, [activeStep]);

    useEffect(() => {
        // request server-side configs
        getConfig();

        // load dictionaries
        let allKeywordObjs = [];
        ['GovCon', 'Legal', 'Financial'].forEach((item) => {
            // auto sort
            const keywordList = require(`../dictionaries/${item}.json`);
            const keywordObjs = keywordList.sort((a, b) => a.localeCompare(b)).map(k => {
                return {
                    "dict": item,
                    "keyword": k
                }
            });

            allKeywordObjs = [...allKeywordObjs, ...keywordObjs];
        });
        allDictionariesRef.current = allKeywordObjs;

        const csiParam = params.get("session_id");
        const successParam = params.get("success");

        if (csiParam && successParam) {
            setActiveStep(5);
            getCheckoutSessionInfo(csiParam);
        }

    }, []);

    const options = {
        clientSecret: clientSecret,
        appearance: {
            theme: 'stripe',
        }
    };

    return (
        <div className="col-lg-12 h-100">
            <div className="row justify-content-center h-100">
                <div className='col-lg-8 h-100 d-flex flex-column'>
                    <div className="d-flex justify-content-center">
                        <div id="homepage-steps-bar"></div>
                        <div className='homepage-step-wrapper'>
                            <div className='homepage-step-label'>1. Upload</div>
                            <div className={`homepage-step-dot ${(activeStep === 1 ? 'active' : '')}`}></div>
                        </div>
                        <div className='homepage-step-wrapper'>
                            <div className='homepage-step-label'>2. Parse</div>
                            <div className={`homepage-step-dot ${(activeStep === 2 || activeStep === 2.5 ? 'active' : '')}`}></div>
                        </div>
                        <div className='homepage-step-wrapper'>
                            <div className='homepage-step-label'>3. Review</div>
                            <div className={`homepage-step-dot ${(activeStep === 3 ? 'active' : '')}`}></div>
                        </div>
                        <div className='homepage-step-wrapper'>
                            <div className='homepage-step-label'>4. Purchase</div>
                            <div className={`homepage-step-dot ${(activeStep === 4 ? 'active' : '')}`}></div>
                        </div>
                        <div className='homepage-step-wrapper'>
                            <div className='homepage-step-label'>5. Download</div>
                            <div className={`homepage-step-dot ${(activeStep === 5 ? 'active' : '')}`}></div>
                        </div>
                    </div>
                    <div id="homepage-body" className='d-flex justify-content-center'>
                        {activeStep === 1 &&
                            <div className='col-lg-6'>
                                <div id="dropzone" className='d-flex flex-column' onDrop={handleDropzoneDrop}>
                                    <div id='dropzone-upper' className='d-flex align-items-center justify-content-center'>
                                        <div className='d-flex flex-column dropzone-msg-wrapper'>
                                            {!stagedFile &&
                                                <div className='dropzone-msg'>Drag & Drop<br />Your File Here.</div>
                                            }
                                            {stagedFile &&
                                                <>
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        {["doc", "docx"].includes(stagedFile.name.split(".").pop()) &&
                                                            <img src={wordIcon} alt="Microsoft Word icon" />
                                                        }
                                                        {["pdf"].includes(stagedFile.name.split(".").pop()) &&
                                                            <img src={pdfIcon} alt="PDF icon" />
                                                        }
                                                        <div className="ms-2">{stagedFile.name}</div>
                                                    </div>
                                                    {isUploading &&
                                                        <div className='d-flex justify-content-center'>
                                                            <ReactLoading type="spin" width={32} color={'#1EC3CC'} />
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div id='dropzone-lower'>
                                        <div className=''>
                                            <button id="dropzone-btn" className='theme-btn' onClick={handleUploadButtonClick}>Upload A File</button>
                                        </div>
                                    </div>
                                    {config && config.maxWordMb && config.maxPdfMb &&
                                        <div id='dropzone-supported-msg'>File Types & Sizes Supported: .doc & .docx {config.maxWordMb}MB Size Cap | .pdf {config.maxPdfMb}MB Size Cap</div>
                                    }
                                    <input type="file" ref={dropzoneFileInputRef} id="dropzone-file-input" onChange={handleFileInputChange} accept="application/pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                                </div>
                            </div>
                        }
                        {activeStep === 2 &&
                            <div className='col-lg-12'>
                                <div className='d-flex justify-content-center h-75 mt-5'>
                                    <div className='col-lg-1 d-flex'>
                                        <div className='back-arrow' onClick={() => setActiveStep(1)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="#21C1FF" className="bi bi-caret-left-fill" viewBox="0 0 16 16" >
                                                <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div className='d-flex flex-column'>
                                            <div className='dictionary-header'>
                                                Add a<br />Dictionary
                                            </div>
                                            <div className='dictionary-body d-flex flex-column mt-4 pe-3'>
                                                <div>Choose & edit one of our prebuilt dictionaries or paste in one of your own. To enter more keywords, enter a new line after each.</div>
                                                <div className='dictionary-options d-flex flex-column mt-4'>
                                                    <div className='d-flex align-items-center mb-2'>
                                                        <input type='checkbox' className='dictionary-option me-2' data-dict-name="GovCon" onChange={handleToggleGovcon} checked={govconIsChecked}></input>
                                                        <div>Govcon Keywords</div>
                                                    </div>
                                                    <div className='d-flex align-items-center mb-2'>
                                                        <input type='checkbox' className='dictionary-option me-2' data-dict-name="Legal" onChange={handleToggleLegal} checked={legalIsChecked}></input>
                                                        <div>Legal Keywords</div>
                                                    </div>
                                                    <div className='d-flex align-items-center mb-2'>
                                                        <input type='checkbox' className='dictionary-option me-2' data-dict-name="Financial" onChange={handleToggleFinancial} checked={financialIsChecked}></input>
                                                        <div>Financial Keywords</div>
                                                    </div>
                                                </div>
                                                <div className='parsing-options mt-4'>
                                                    <select className='form-select' ref={parseTypeRef}>
                                                        <option defaultValue>Section Parse</option>
                                                        <option>Paragraph Parse</option>
                                                    </select>
                                                </div>
                                                <button id="parse-document-btn" className='theme-btn mt-4' onClick={handleParseDocumentClick}>Parse Document</button>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-lg-1'></div>
                                    <div className='col-lg-4'>
                                        <div className="dictionary-editor-wrapper">
                                            <div className='dictionary-editor-header'>Your keywords:</div>
                                            <div className='dictionary-editor-body'>
                                                <textarea ref={textAreaRef} onChange={handleTextAreaChange} value={keywordLines}></textarea>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className='col-lg-1'></div>
                                </div>
                            </div>
                        }
                        {activeStep === 2.5 &&
                            <div className='d-flex flex-column align-items-center'>
                                <Lottie loop animationData={lottieJson} play style={{ width: 297, height: 297 }} />
                                <div className='parse-waiting-msg'>Parsing "{stagedFile.name}" ...</div>
                            </div>
                        }
                        {activeStep === 3 &&
                            <div className='col-lg-12 h-100'>
                                <div className='row preview-content'>
                                    <div className='col-lg-1 d-flex'>
                                        <div className='back-arrow mt-4' onClick={() => setActiveStep(2)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="#21C1FF" className="bi bi-caret-left-fill" viewBox="0 0 16 16" >
                                                <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className=' col-lg-10 d-flex flex-column align-items-center mt-4 h-100'>
                                        <div className='preview-title'>Review your results</div>
                                        <div className='preview-subtitle my-3'>Here is a partial preview of your parse document.  If you need to make any adjustments, feel free to start over or click the return arrow to the left.</div>
                                        <div className='preview-wrapper' ref={resultsPreviewRef}>

                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            <button id="accept-preview-btn" className='theme-btn mt-4' onClick={handlePurchaseClick}>Purchase & Download</button>
                                        </div>
                                    </div>
                                    <div className='col-lg-1'></div>
                                </div>
                            </div>
                        }
                        {activeStep === 5 &&
                            <div className='col-lg-8 h-100'>
                                <div className='d-flex flex-column align-items-center mt-5 preview-content'>
                                    <div className='preview-title'>Download Your Data</div>
                                    <div className='preview-subtitle text-center my-4'>Your download should start automatically.<br />If it doesn't, click the button below.</div>
                                    <button id="purchase-btn" className='theme-btn mt-4' onClick={handleDownloadClick}>Download</button>
                                    <button id="start-over-btn" className='theme-btn mt-4' onClick={handleStartOver}>Parse Another Document</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div id="main-modal" className={`modal ${modalOptions.visible ? 'modal-show' : ''}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 text-center">{modalOptions.body}</div>
                                <button type="button" className="btn theme-btn ms-4" data-dismiss="modal" onClick={handleModalClose}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePage;